<template>
  <div id="top-header">
    <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5
      :dur="5"
      style="width: 650px"
      class="header-center-decoration"
    />
    <div class="options">
      <div class="tab-project">
        <div @click="showOpenListFn()" class="more cursor-pointer">
          <!-- <img  src="@/assets/screen/more.png" alt=""> -->
          <div class="imgbg"></div>
        </div>
        <!-- <div class="tab-list" v-if="showOpenList">
          <div class="select-tab cursor-pointer" v-if="SwitchInfo.companyScreenPermissionStatus == 1" @click="open(1)">
            {{SwitchInfo.companyScreenTitle}}
          </div>
          <div class="select-tab cursor-pointer" v-if="SwitchInfo.salaryScreenPermissionStatus == 1"  @click="open(3)">
            {{SwitchInfo.salaryScreenTitle}}
          </div>
        </div> -->
      </div>
      <div @click="refresh">
        <i class="el-icon-refresh" :style="{transform:'rotate('+rotate+'deg)'}"> </i><span class="sxsj">刷新数据</span>
      </div>
      <div>
        <i class="el-icon-full-screen" @click="toggleFullScreen"></i>
      </div>
    </div>

    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">数字化劳动成果大屏</div>
    <div class='model-shadow' v-if='showOpenList'>
      <div class="models">
        <div class="models-box" v-if="!showNull">
          <div class="modelcontent cursor-pointer"  @click="open(1)" v-if="SwitchInfo.companyScreenPermissionStatus == 1" >
            <div class="modeltext" >
              {{SwitchInfo.companyScreenTitle}}
            </div>
          </div>
          <div class="modelcontent cursor-pointer" @click="open(3)" v-if="SwitchInfo.salaryScreenPermissionStatus == 1">
            <div class="modeltext"   >
              {{SwitchInfo.salaryScreenTitle}}
            </div>
          </div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
        <div class="models-box" v-else>
          <div class="zwqx">暂无权限</div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  screenSwitchInfo
} from "../api.js"
export default {
  data() {
    return {
      rotate:0,
      showOpenList:false,
      SwitchInfo:[],
      type:"",
      showNull:false
    }
  },

  methods: {
    toggleFullScreen() {
      const elem = document.getElementsByTagName('body')[0]
      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch((err) => {
          console.log(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
      } else {
        document.exitFullscreen();
      }
      this.$emit('toggleFullScreen')

    },
    refresh() {
     this.rotate+=360
      this.$emit("refresh");
    },

    showOpenListFn(){
      screenSwitchInfo().then(res=>{
        if(res.code == 200 && res.data){
          this.SwitchInfo = res.data
          if(this.SwitchInfo.companyScreenPermissionStatus == 0 && this.SwitchInfo.salaryScreenPermissionStatus == 0){
            console.log(1)
            this.showOpenList = !this.showOpenList
            this.showNull = true
          }else{
            this.showOpenList = !this.showOpenList
            this.showNull = false
          }
          // this.SwitchInfo.projectScreenPermissionStatus = 1
          // this.SwitchInfo.salaryScreenPermissionStatus = 1
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    open(type){
      if(type==1){
        this.showOpenList = !this.showOpenList
        this.$router.push({
          path:"/"
        })

      }
      if(type==3){
        this.$router.push({
          path:"/salary"
        })
        this.showOpenList = !this.showOpenList
      }




    },
    closeTab(){
        this.showOpenList = false
      },
  },
};
</script>

<style lang="less">
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: 10px;
  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }
  .header-left-decoration,
  .header-right-decoration {
    width: 25%;
    height: 60px;
  }
  .center-title {
    position: absolute;
    font-size:0.3rem;
    font-weight: bold;
    left: 50%;
    top: 0.18rem;
    transform: translateX(-50%);
    color: rgb(0, 228, 255);
  }
}
.options {
  position: absolute;
  top:0rem;
 
  right: 60px;
  display: flex;
  width: 2.2rem;
  gap: 20px;
  color: rgb(0, 228, 255);
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    i {
      font-size: 0.24rem;
    }
    span {
      font-size: 0.18rem;
    }
  }
}

.el-icon-refresh {
  transform: rotate(0deg);
  transition: transform 1s ease-in-out;
}

.tab-project{
    position: relative;
    .more{
    width: 0.45rem;
    height:.45rem;
    vertical-align: middle;
    .imgbg{
      width: 0.45rem;
      height:.45rem;
      background: url("../../../assets/screen/more.png") no-repeat center center;
      background-size: 80%;
    }
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  }
  .tab-list{
    position:absolute;
    top:35px;
    left: -65px;
    width: 180px;
    z-index: 888;
    min-height: 16px;
    background: #08436d;
    border: 1px solid #00adff;
    padding: 10px;
    box-sizing: border-box;
  }
  .select-tab{
    font-size: 16px;
    border-bottom: 1px solid #00adff;
    margin-top: 10px;
    height: 25px;
  }
  .cursor-pointer{
    cursor:pointer;
  }
  .model-shadow{
      position: absolute;
      top: 0;
      left: 0;
      width: 19.20rem;
      min-height: 10.80rem;
      background:rgba(1,14,34,.7);
      z-index: 1000;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .models{
        position:absolute;
        top: 27%;
        left: 50%;
        margin-left: -4.00rem;
        margin-top: -2.00rem;
        width: 8.00rem;
        height: 7.00rem;
        background: url("../../../assets/screen/modelBox.png") no-repeat center center;
        background-size: 100%;
 
        .models-box{
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 1.4rem;
          box-sizing: border-box;
          .modelcontent{
            width: 2.4rem;
            height: 4rem;
            background: url("../../../assets/screen/modelPro.png") no-repeat center center;
            background-size: 100%;
            padding-top: 1.8rem;
            box-sizing: border-box;
            .modeltext{
              font-size: 0.2rem;
              color: #D0FFFD;
              padding: 0.1rem;
              box-sizing: border-box;
            }
          }
          .close{
              position: absolute;
              width: 0.5rem;
              height: 0.5rem;
              bottom: 0;
              left: 50%;
              z-index: 1000;
              background: url("../../../assets/screen/close.png") no-repeat center center;
              background-size: 100%;
            }
        }
        .zwqx{
          font-size: 0.22rem;
          margin-top: 2rem;
          color:#00adff;
        }
      }
  
      .model{
        position:absolute;
        top: 25%;
        left: 50%;
        margin-left: -2.00rem;
        width: 4.00rem;
        height: 3.00rem;
        background:rgba(1,18,39,.8);
        border: .02rem solid #00adff;
        border-radius: .05rem;
        box-shadow: 0 0 .12rem rgb(0 173 255 / 75%);
        .title{
          color: #58d2ff;
          font-size: .16rem;
          margin-top: .20rem;
        }
        .content{
          margin-top: .30rem;
          .select-box{
            margin-top: .20rem;
            height:.48rem;
            .txt-style{
              color: #58d2ff;
              font-size: .14rem;
            }
            .select{
              width: 1.40rem;
              height: .30rem;
              background:rgba(1,18,39,.8);
              color:#00adff;
              border: .01rem solid #00adff;
              border-radius: .05rem;
              box-shadow: 0 0 .06rem rgb(0 173 255 / 75%);
              outline: none
            }
            .check_company{
              margin-left:.18rem;
              margin-top: .10rem;
              color:#58d2ff;
              font-size: .12rem;
            }
          }
        }
        .footer{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: .50rem;
          display: flex;
          justify-content: flex-end;
          .submit{
            margin-right: .30rem;
            font-size: .14rem;
          }
          .close{
            margin-right: .10rem;
            font-size: .14rem;
          }
          .btn{
            width: .50rem;
            height: .30rem;
            line-height: .30rem;
            color: #58d2ff;
            border: .01rem solid #00adff;
            border-radius: .05rem;
            cursor:pointer;
          }
        }
      }
    } 
</style>
