<template>
  <dv-full-screen-container>
    <div class="screen" id="fullScreen">
      <!-- src="@/assets/images/bj.mp4"
      src="https://llcb.oss-cn-shanghai.aliyuncs.com/fdyqd/dev/202403/bj2024030516033943408.mp4"
      
      -->
      <img class="bg-video" src="@/assets/screen/Vbg1.png" alt="">
      <!-- <img class="bg-video" src="@/assets/screen/Vbg2.png" alt=""> -->
      <!-- <video
        class="bg-video"
        autoplay
        muted
        loop
         src="@/assets/bj.mp4"
        :style="videoStyle"
        controls
        preload 
      ></video> -->
      <div class="bg"></div>
      <top-header @refresh="reload"></top-header>
      <div class="main-content">
        <!-- 123 -->
        <router-view ref="child"></router-view>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import TopHeader from "./components/top-header.vue";
export default {
  components: {
    TopHeader,
  },
  data() {
    return {
      videoStyle: {},
      is360:"",
    };
  },
  computed: {},
  mounted() {
    this.is360 = false;
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("360se") > -1 || userAgent.indexOf("360ee") > -1) {
      this.is360 = true;
    }
    console.log(userAgent)
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    reload() {
      console.log("执行")
      this.$refs.child.getData();
    },
    handleResize() {
      this.$refs.child.handleResize();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="less" scoped>
.bg-video {
  position: fixed;
  z-index: -2;
  width: 100%;
  top: 0;
  left: 0;
}
.bg {
  position: fixed;
  width: 100%;
  height: 120%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}
::v-deep .projectName,
.top-info {
  color: #fff;
  font-size: 20px;
}
::v-deep .table-cell {
  border: 1px solid #5b6f8f;
}
::v-deep .row-cell {
  border: 1px solid #5b6f8f;
  color: #80b4e0;
  background: rgba(13, 29, 77, 0.8);
  height: 60px;
  text-align: center;
}
::v-deep .el-table__header-wrapper .el-table__cell.table-header {
  border: 1px solid #5b6f8f;
  color: #fff;
  background: rgba(13, 29, 77, 0.8);
  border-bottom: 1px solid #5b6f8f;
  text-align: center;
  height: 60px;
  text-align: center;
}
::v-deep .cell-text {
  font-size: 16px;
  color: #80b4e0;
}
::v-deep .cell-number {
  font-size: 18px;
  font-weight: bold;
  color: #80b4e0;
}
.screen {
  min-height: 100%;
  padding: 10px;
}

.top-select {
  color: #fff;
}
.main-content {
  padding: 20px;
}
::v-deep .el-pagination__total {
  color: #ddd;
}
::v-deep .el-pager li {
  background: transparent;
  color: #fff;
}
::v-deep .el-pager li.active {
  background: transparent;
  color: #fff;
  font-size: 15px;
}
::v-deep .el-pagination button:disabled {
  background: transparent;
}
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
  background: transparent;
  color: #fff;
}
::v-deep .el-table__empty-block {
  background: rgb(13, 29, 77);
  border-left: 1px solid rgb(91, 111, 143);
  border-right: 1px solid rgb(91, 111, 143);
}

::v-deep .el-table::before,
.el-table--group::after,
.el-table--border::after {
  background: transparent;
}

::v-deep .table-container {
  border: 2px solid #2773e6;
}
::v-deep .el-table td.row-cell {
  border: 1px solid rgb(91, 111, 143);
  border-bottom: 0px solid rgba(0, 0, 0, 0);
  color: rgb(128, 180, 224);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #10276bcf;
}

::v-deep .el-button.el-button--default {
  background: transparent;
  color: #fff;
}
::v-deep .el-button.el-button--default:hover,
.el-button:focus {
  background-color: #80b4e0;
}
</style>
